import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
const Footer = () => (
  <footer className="bg-gray-900 text-white py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <div className="flex items-center space-x-2 mb-6">
           
            <span className="text-blue-300 flex items-center space-x-2">
              <div className="h-28 w-auto flex items-center overflow-hidden">
                <img 
                  src="logo.png" 
                  alt="Rayosense Logo" 
                  className="h-full w-auto object-contain object-center"
                />
              </div>
            </span>
          </div>
          <p className="text-gray-400">
            Transforming businesses through intelligent computer vision solutions.
          </p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Solutions</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">Computer Vision</a></li>
            <li><a href="#" className="hover:text-white transition-colors">AI Analytics</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Edge Computing</a></li>
          </ul>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Company</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">About Us</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Careers</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Contact</a></li>
          </ul>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Connect</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">LinkedIn</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Twitter</a></li>
            <li><a href="#" className="hover:text-white transition-colors">GitHub</a></li>
          </ul>
        </div>
      </div>
      
      <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
        <p>&copy; {new Date().getFullYear()} Rayosense. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

export default Footer;
