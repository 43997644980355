import React, { useState, useEffect, useRef } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  Pause,
  Volume2,
  VolumeX,
  Maximize,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
import VideoModal from './VideoModel';

const Hero = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const youtubeVideoId = '3p4ywT1DJlA';

  return (
    <section className="relative min-h-screen flex items-center bg-gradient-to-br from-gray-900 via-blue-900 to-blue-800 text-white overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 grid-background opacity-20" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10 pt-20">
        <div className="max-w-4xl mx-auto text-center">
          <div 
            className="inline-block px-10 py-2 mb-6 animate-fade-in"
            style={{ animationDelay: '0.2s' }}
          >
            <span className="text-blue-300 flex items-center space-x-2">
              <div className="h-32 w-auto flex items-center overflow-hidden">
                <img 
                  src="logo.png" 
                  alt="Rayosense Logo" 
                  className="h-full w-auto object-contain object-center"
                />
              </div>
            </span>
          </div>
          
          <h1 className="text-5xl md:text-7xl font-extrabold mb-8 leading-tight animate-fade-in-up"
    style={{ animationDelay: '0.4s', letterSpacing: '-0.05em' }}>
  Transform Your World <br />
  <span className="bg-gradient-to-r from-blue-500 via-teal-400 to-cyan-500 bg-clip-text text-transparent animate-pulse"
        style={{ animationDelay: '0.6s' }}>
    Through the Power of AI
  </span>
</h1>

          
          <p className="text-xl md:text-2xl text-blue-100 mb-12 leading-relaxed animate-fade-in-up"
             style={{ animationDelay: '0.6s' }}>
            Empower your business with cutting-edge computer vision and AI solutions
            that transform raw visual data into actionable insights.
          </p>
          
          <div className="flex flex-col sm:flex-row gap-6 justify-center animate-fade-in-up"
               style={{ animationDelay: '0.8s' }}>
            <button className="group bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-blue-500/25 flex items-center justify-center space-x-2">
              <span>Start Your Journey</span>
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
            </button>
            <button 
              onClick={() => setIsModalOpen(true)}
              className="group bg-white/10 hover:bg-white/20 text-white px-8 py-4 rounded-full transition-all duration-300 backdrop-blur-sm flex items-center justify-center space-x-2"
            >
              <Play className="w-5 h-5 group-hover:scale-110 transition-transform duration-300" />
              <span>Watch Demo</span>
            </button>
          </div>
        </div>
      </div>
      
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent" />

      {/* Video Modal */}
      <VideoModal 
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      videoId={youtubeVideoId}
    />
    </section>
  );
};

export default Hero;