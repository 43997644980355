import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
const Industries = () => {
  const industries = [
    {
      icon: Factory,
      title: "Manufacturing",
      description: "Optimize production processes with AI-powered visual inspection and quality control.",
      features: [
        "Real-time defect detection",
        "Predictive maintenance",
        "Assembly line optimization",
        "Quality assurance automation"
      ],
      bgColor: "blue"
    },
    {
      icon: Building2,
      title: "Smart Cities",
      description: "Transform urban spaces with intelligent monitoring and management systems.",
      features: [
        "Traffic flow optimization",
        "Public safety monitoring",
        "Infrastructure management",
        "Environmental monitoring"
      ],
      bgColor: "green"
    },
    {
      icon: ShoppingBag,
      title: "Retail",
      description: "Enhance customer experience and operations with AI-driven insights.",
      features: [
        "Customer behavior analysis",
        "Inventory management",
        "Queue management",
        "Loss prevention"
      ],
      bgColor: "purple"
    },
    {
      icon: Building,
      title: "Real Estate",
      description: "Modernize property management with intelligent monitoring solutions.",
      features: [
        "Occupancy tracking",
        "Security surveillance",
        "Energy optimization",
        "Facility management"
      ],
      bgColor: "orange"
    }
  ];

  return (
    <section id="industries" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Industry Solutions
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Transforming Industries
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Tailored AI solutions that address specific industry challenges
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="group relative overflow-hidden bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <div className={`absolute inset-0 bg-gradient-to-br from-${industry.bgColor}-500/10 to-${industry.bgColor}-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
              
              <div className="p-8">
                <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 bg-${industry.bgColor}-50 group-hover:bg-${industry.bgColor}-100 transition-colors duration-300`}>
                  <industry.icon className={`text-${industry.bgColor}-600 w-8 h-8`} />
                </div>

                <h3 className="text-xl font-semibold mb-4">
                  {industry.title}
                </h3>

                <p className="text-gray-600 mb-6">
                  {industry.description}
                </p>

                <ul className="space-y-3">
                  {industry.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                <button className="mt-8 w-full py-3 px-6 rounded-lg bg-gray-50 text-gray-700 font-medium hover:bg-gray-100 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Industries