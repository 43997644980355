import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
const Projects = () => {
  const projects = [
    {
      title: "Fraud Detection in Theaters",
      description: "AI-powered fraud detection (tiketing, F&B) real-time analytics.",
      status: "Active",
      progress: 45,
      metrics: {
        accuracy: "95%",
        processing: "Real-time",
        deployment: "30+ theaters"
      },
      icon: Building2,
      color: "blue"
    },
    {
      title: "AI Based School Management",
      description: "AI-powered school management system with real-time analytics.",
      status: "Active",
      progress: 30,
      metrics: {
        accuracy: "99.5%",
        processing: "< 5 secs",
        deployment: "Pilot Phase"
      },
      icon: Factory,
      color: "blue"
    },
    {
      title: "AI based OCR Solution",
      description: "Transform your business with AI-powered document processing that instantly converts paper chaos into organized, actionable digital data.",
      status: "Active",
      progress: 55,
      metrics: {
        accuracy: "95%",
        processing: "Batch Processing",
        deployment: "Pilot Phase"
      },
      icon: ShoppingBag,
      color: "blue"
    }
  ];

  return (
    <section id="projects" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Success Stories
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Featured Projects
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Real-world implementations delivering measurable results
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden"
            >
              <div className={`bg-${project.color}-50 p-6`}>
                <div className="flex justify-between items-start mb-4">
                  <div className={`w-12 h-12 rounded-lg bg-${project.color}-100 flex items-center justify-center`}>
                    <project.icon className={`text-${project.color}-600 w-6 h-6`} />
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm bg-${project.color}-100 text-${project.color}-700`}>
                    {project.status}
                  </span>
                </div>
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-gray-600">{project.description}</p>
              </div>

              <div className="p-6">
                <div className="mb-4">
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600">Progress</span>
                    <span className="font-medium">{project.progress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`bg-${project.color}-600 h-2 rounded-full transition-all duration-300`}
                      style={{ width: `${project.progress}%` }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 pt-4 border-t">
                  {Object.entries(project.metrics).map(([key, value]) => (
                    <div key={key} className="text-center">
                      <div className="text-lg font-semibold text-gray-800">
                        {value}
                      </div>
                      <div className="text-xs text-gray-500 capitalize">
                        {key}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects