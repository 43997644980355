import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
import HeaderComp from './components/Header';
import HeroComp from './components/Hero';
import FooterComp from './components/Footer';
import ContactComp from './components/Contact';
import ExpertiseComp from './components/Expertise';
import IndustriesComp from './components/Industries';
import ProjectsComp from './components/Projects';

// Header Component - Enhanced with better navigation and animations
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Solutions', href: '#solutions' },
    { name: 'Industries', href: '#industries' },
    { name: 'Expertise', href: '#expertise' },
    { name: 'Projects', href: '#projects' },
    { name: 'Contact', href: '#contact' }
  ];

  return (
    <header 
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white/90 backdrop-blur-md shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="w-8 h-8 bg-blue-600 rounded-lg flex items-center justify-center">
              <Brain className="text-white w-5 h-5" />
            </div>
            <h1 className="text-2xl font-bold font-inter">
              Rayo<span className="text-blue-600">sense</span>
            </h1>
          </div>
          
          <nav className="hidden lg:flex items-center space-x-8">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`relative group py-2 ${
                  isScrolled ? 'text-gray-800' : 'text-white'
                }`}
              >
                <span className="relative z-10">{item.name}</span>
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-600 group-hover:w-full transition-all duration-300"></span>
              </a>
            ))}
            <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors duration-300">
              Get Started
            </button>
          </nav>
          
          <button 
            className="lg:hidden z-50"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="text-gray-800" /> : <Menu className={isScrolled ? 'text-gray-800' : 'text-white'} />}
          </button>
        </div>
      </div>
      
      {/* Mobile Menu */}
      <div className={`
        fixed inset-0 bg-white/95 backdrop-blur-md transition-transform duration-300 lg:hidden
        ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
      `}>
        <div className="container mx-auto px-4 py-20">
          <nav className="flex flex-col space-y-6">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-2xl font-semibold text-gray-800 hover:text-blue-600 transition-colors duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </a>
            ))}
            <button className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition-colors duration-300 text-lg">
              Get Started
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

// Enhanced Hero Section with animations and better visual hierarchy
const Hero = () => (
  <section className="relative min-h-screen flex items-center bg-gradient-to-br from-gray-900 via-blue-900 to-blue-800 text-white overflow-hidden">
    <div className="absolute inset-0">
      <div className="absolute inset-0 grid-background opacity-20" />
    </div>
    
    <div className="container mx-auto px-4 relative z-10 pt-20">
      <div className="max-w-4xl mx-auto text-center">
        <div 
          className="inline-block px-4 py-2 bg-blue-500/20 rounded-full mb-6 animate-fade-in"
          style={{ animationDelay: '0.2s' }}
        >
          <span className="text-blue-300 flex items-center space-x-2">
            <Zap className="w-4 h-4" />
            <span>Next-Gen AI Solutions</span>
          </span>
        </div>
        
        <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight animate-fade-in-up"
            style={{ animationDelay: '0.4s' }}>
          Revolutionize Your Vision<br />
          <span className="bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">
            With AI Intelligence
          </span>
        </h1>
        
        <p className="text-xl md:text-2xl text-blue-100 mb-12 leading-relaxed animate-fade-in-up"
           style={{ animationDelay: '0.6s' }}>
          Empower your business with cutting-edge computer vision and AI solutions
          that transform raw visual data into actionable insights.
        </p>
        
        <div className="flex flex-col sm:flex-row gap-6 justify-center animate-fade-in-up"
             style={{ animationDelay: '0.8s' }}>
          <button className="group bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-blue-500/25 flex items-center justify-center space-x-2">
            <span>Start Your Journey</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
          </button>
          <button className="group bg-white/10 hover:bg-white/20 text-white px-8 py-4 rounded-full transition-all duration-300 backdrop-blur-sm flex items-center justify-center space-x-2">
            <Play className="w-5 h-5" />
            <span>Watch Demo</span>
          </button>
        </div>
      </div>
    </div>
    
    <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent" />
  </section>
);

// Enhanced Expertise Section with animations and better cards
const Expertise = () => {
  const expertiseAreas = [
    {
      icon: Eye,
      title: "Computer Vision",
      description: "State-of-the-art vision systems for real-time analysis and monitoring with unprecedented accuracy.",
      color: "blue"
    },
    {
      icon: Shield,
      title: "Security & Safety",
      description: "Advanced security solutions with real-time threat detection and intelligent monitoring capabilities.",
      color: "red"
    },
    {
      icon: Brain,
      title: "Neural Networks",
      description: "Custom-built neural networks optimized for specific industry applications and use cases.",
      color: "purple"
    },
    {
      icon: BarChart3,
      title: "Analytics Platform",
      description: "Comprehensive analytics dashboard with real-time insights and predictive capabilities.",
      color: "green"
    },
    {
      icon: Zap,
      title: "Edge Computing",
      description: "Efficient edge-based processing for real-time decision making and reduced latency.",
      color: "yellow"
    },
    {
      icon: Users,
      title: "Human-AI Integration",
      description: "Seamless integration of AI systems with human workflows for optimal efficiency.",
      color: "indigo"
    }
  ];

  return (
    <section id="expertise" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Our Capabilities
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Expertise That Drives Innovation
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leveraging cutting-edge AI technologies to deliver transformative solutions
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {expertiseAreas.map((area, index) => (
            <div
              key={index}
              className="group bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 bg-${area.color}-50 group-hover:bg-${area.color}-100 transition-colors duration-300`}>
                <area.icon className={`text-${area.color}-600 w-8 h-8`} />
              </div>
              
              <h3 className="text-xl font-semibold mb-4 group-hover:text-blue-600 transition-colors duration-300">
                {area.title}
              </h3>
              
              <p className="text-gray-600 leading-relaxed">
                {area.description}
              </p>
              
              <div className="mt-6 flex items-center text-blue-600 font-medium">
                <span className="mr-2">Learn More</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-2 transition-transform duration-300" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Enhanced Industries Section with better visuals and interactions
const Industries = () => {
  const industries = [
    {
      icon: Factory,
      title: "Manufacturing",
      description: "Optimize production processes with AI-powered visual inspection and quality control.",
      features: [
        "Real-time defect detection",
        "Predictive maintenance",
        "Assembly line optimization",
        "Quality assurance automation"
      ],
      bgColor: "blue"
    },
    {
      icon: Building2,
      title: "Smart Cities",
      description: "Transform urban spaces with intelligent monitoring and management systems.",
      features: [
        "Traffic flow optimization",
        "Public safety monitoring",
        "Infrastructure management",
        "Environmental monitoring"
      ],
      bgColor: "green"
    },
    {
      icon: ShoppingBag,
      title: "Retail",
      description: "Enhance customer experience and operations with AI-driven insights.",
      features: [
        "Customer behavior analysis",
        "Inventory management",
        "Queue management",
        "Loss prevention"
      ],
      bgColor: "purple"
    },
    {
      icon: Building,
      title: "Real Estate",
      description: "Modernize property management with intelligent monitoring solutions.",
      features: [
        "Occupancy tracking",
        "Security surveillance",
        "Energy optimization",
        "Facility management"
      ],
      bgColor: "orange"
    }
  ];

  return (
    <section id="industries" className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Industry Solutions
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Transforming Industries
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Tailored AI solutions that address specific industry challenges
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {industries.map((industry, index) => (
            <div
              key={index}
              className="group relative overflow-hidden bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
            >
              <div className={`absolute inset-0 bg-gradient-to-br from-${industry.bgColor}-500/10 to-${industry.bgColor}-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
              
              <div className="p-8">
                <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 bg-${industry.bgColor}-50 group-hover:bg-${industry.bgColor}-100 transition-colors duration-300`}>
                  <industry.icon className={`text-${industry.bgColor}-600 w-8 h-8`} />
                </div>

                <h3 className="text-xl font-semibold mb-4">
                  {industry.title}
                </h3>

                <p className="text-gray-600 mb-6">
                  {industry.description}
                </p>

                <ul className="space-y-3">
                  {industry.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <Check className="w-5 h-5 text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                <button className="mt-8 w-full py-3 px-6 rounded-lg bg-gray-50 text-gray-700 font-medium hover:bg-gray-100 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Enhanced Projects Section with better visualization
const Projects = () => {
  const projects = [
    {
      title: "Smart City Vision",
      description: "AI-powered urban monitoring system with real-time analytics.",
      status: "Active",
      progress: 85,
      metrics: {
        accuracy: "98%",
        processing: "Real-time",
        deployment: "50+ cities"
      },
      icon: Building2,
      color: "blue"
    },
    {
      title: "Factory Intelligence",
      description: "Advanced manufacturing quality control and process optimization.",
      status: "Active",
      progress: 92,
      metrics: {
        accuracy: "99%",
        processing: "< 100ms",
        deployment: "200+ lines"
      },
      icon: Factory,
      color: "blue"
    },
    {
      title: "RetailSense AI",
      description: "Comprehensive retail analytics and customer behavior platform.",
      status: "Active",
      progress: 78,
      metrics: {
        accuracy: "95%",
        processing: "Real-time",
        deployment: "1000+ stores"
      },
      icon: ShoppingBag,
      color: "blue"
    }
  ];

  return (
    <section id="projects" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Success Stories
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Featured Projects
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Real-world implementations delivering measurable results
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden"
            >
              <div className={`bg-${project.color}-50 p-6`}>
                <div className="flex justify-between items-start mb-4">
                  <div className={`w-12 h-12 rounded-lg bg-${project.color}-100 flex items-center justify-center`}>
                    <project.icon className={`text-${project.color}-600 w-6 h-6`} />
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm bg-${project.color}-100 text-${project.color}-700`}>
                    {project.status}
                  </span>
                </div>
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-gray-600">{project.description}</p>
              </div>

              <div className="p-6">
                <div className="mb-4">
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600">Progress</span>
                    <span className="font-medium">{project.progress}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`bg-${project.color}-600 h-2 rounded-full transition-all duration-300`}
                      style={{ width: `${project.progress}%` }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 pt-4 border-t">
                  {Object.entries(project.metrics).map(([key, value]) => (
                    <div key={key} className="text-center">
                      <div className="text-lg font-semibold text-gray-800">
                        {value}
                      </div>
                      <div className="text-xs text-gray-500 capitalize">
                        {key}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Enhanced Contact Form with better validation and feedback
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
    
    // Simulate API call
    setTimeout(() => {
      setStatus('success');
      setFormData({ name: '', email: '', company: '', message: '' });
    }, 2000);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-2xl shadow-xl p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Full Name *
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({...formData, name: e.target.value})}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Name *
          </label>
          <input
            type="text"
            value={formData.company}
            onChange={(e) => setFormData({...formData, company: e.target.value})}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
            required
          />
        </div>
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email Address *
        </label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({...formData, email: e.target.value})}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
          required
        />
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Message *
        </label>
        <textarea
          value={formData.message}
          onChange={(e) => setFormData({...formData, message: e.target.value})}
          rows="4"
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
          required
        />
      </div>

      <button
        type="submit"
        disabled={status === 'sending'}
        className={`w-full py-3 px-6 rounded-lg text-white font-medium transition-all duration-300
          ${status === 'sending' 
            ? 'bg-gray-400 cursor-not-allowed' 
            : 'bg-blue-600 hover:bg-blue-700'}`}
      >
        {status === 'sending' ? 'Sending...' : 'Send Message'}
      </button>

      {status === 'success' && (
        <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-lg flex items-center">
          <Check className="w-5 h-5 mr-2" />
          Thank you! We'll be in touch soon.
        </div>
      )}
    </form>
  );
};

// Contact Section Component
const Contact = () => (
  <section id="contact" className="py-20 bg-white">
    <div className="container mx-auto px-4">
      <div className="text-center mb-16">
        <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
          Get In Touch
        </span>
        <h2 className="text-4xl font-bold mt-2 mb-4">
          Start Your AI Journey Today
        </h2>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Ready to transform your business with AI? Contact us to discuss your needs.
        </p>
      </div>
      <div className="max-w-2xl mx-auto">
        <ContactForm />
      </div>
    </div>
  </section>
);


// Footer Component
const Footer = () => (
  <footer className="bg-gray-900 text-white py-12">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <div className="flex items-center space-x-2 mb-6">
            <div className="w-8 h-8 bg-blue-600 rounded-lg flex items-center justify-center">
              <Brain className="text-white w-5 h-5" />
            </div>
            <h3 className="text-xl font-bold">
              Rayo<span className="text-blue-600">sense</span>
            </h3>
          </div>
          <p className="text-gray-400">
            Transforming businesses through intelligent computer vision solutions.
          </p>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Solutions</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">Computer Vision</a></li>
            <li><a href="#" className="hover:text-white transition-colors">AI Analytics</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Edge Computing</a></li>
          </ul>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Company</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">About Us</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Careers</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Contact</a></li>
          </ul>
        </div>
        
        <div>
          <h4 className="text-lg font-semibold mb-4">Connect</h4>
          <ul className="space-y-2 text-gray-400">
            <li><a href="#" className="hover:text-white transition-colors">LinkedIn</a></li>
            <li><a href="#" className="hover:text-white transition-colors">Twitter</a></li>
            <li><a href="#" className="hover:text-white transition-colors">GitHub</a></li>
          </ul>
        </div>
      </div>
      
      <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
        <p>&copy; {new Date().getFullYear()} Rayosense. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

// Main App Component
export default function App() {
  return (
     <div className="min-h-screen bg-white">
       <HeaderComp />
       <main className="overflow-hidden">
         <HeroComp />
         <ExpertiseComp />
         <IndustriesComp />
         <ProjectsComp />
         <ContactComp />
       </main>
       <FooterComp />
     </div>
  
    

    
  );
}


// Add required styles to your CSS
const globalStyles = `
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fade-in-up {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-fade-in {
    animation: fade-in 0.6s ease-out forwards;
  }

  .animate-fade-in-up {
    animation: fade-in-up 0.6s ease-out forwards;
  }
`;