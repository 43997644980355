import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  Menu, 
  X, 
  Brain, 
  Eye, 
  Shield, 
  Users, 
  Building2,
  Factory,
  Microscope,
  Car,
  GraduationCap,
  ArrowRight,
  Zap,
  BarChart3,
  Camera,
  Lightbulb,
  Play,
  ShoppingBag,
  Building,
  Check
} from 'lucide-react';
const Expertise = () => {
  const expertiseAreas = [
    {
      icon: Eye,
      title: "Computer Vision",
      description: "State-of-the-art vision systems for real-time analysis and monitoring with unprecedented accuracy.",
      color: "blue"
    },
    {
      icon: Shield,
      title: "Security & Safety",
      description: "Advanced security solutions with real-time threat detection and intelligent monitoring capabilities.",
      color: "red"
    },
    {
      icon: Brain,
      title: "Neural Networks",
      description: "Custom-built neural networks optimized for specific industry applications and use cases.",
      color: "purple"
    },
    {
      icon: BarChart3,
      title: "Analytics Platform",
      description: "Comprehensive analytics dashboard with real-time insights and predictive capabilities.",
      color: "green"
    },
    {
      icon: Zap,
      title: "Edge Computing",
      description: "Efficient edge-based processing for real-time decision making and reduced latency.",
      color: "yellow"
    },
    {
      icon: Users,
      title: "Human-AI Integration",
      description: "Seamless integration of AI systems with human workflows for optimal efficiency.",
      color: "indigo"
    }
  ];

  return (
    <section id="expertise" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            Our Capabilities
          </span>
          <h2 className="text-4xl font-bold mt-2 mb-4">
            Expertise That Drives Innovation
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Leveraging cutting-edge AI technologies to deliver transformative solutions
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {expertiseAreas.map((area, index) => (
            <div
              key={index}
              className="group bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 rounded-xl flex items-center justify-center mb-6 bg-${area.color}-50 group-hover:bg-${area.color}-100 transition-colors duration-300`}>
                <area.icon className={`text-${area.color}-600 w-8 h-8`} />
              </div>
              
              <h3 className="text-xl font-semibold mb-4 group-hover:text-blue-600 transition-colors duration-300">
                {area.title}
              </h3>
              
              <p className="text-gray-600 leading-relaxed">
                {area.description}
              </p>
              
              <div className="mt-6 flex items-center text-blue-600 font-medium">
                <span className="mr-2">Learn More</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-2 transition-transform duration-300" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Expertise